import { Button, IconButton, MenuItem, Select, Tooltip } from '@mui/material';
import type { Condition } from '@repo/api-gw-sdk';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import {
  ListOperator,
  CombineOperator,
  type FilterProperty,
  PropertyPolicyRelation,
} from '@/types/advanceFilter';

import { ConditionMenu } from './ConditionMenu';
import { ConditionContainer, ConditionGroup } from './ConditionViewer';
import { OperatorSelect } from './OperatorSelect';
import PropertySelect from './PropertySelect';

export const inlineDropdownStyle = {
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },

  ':hover': {
    backgroundColor: '#E7F0F3',
  },
};

export interface ConditionEditorOptions {
  supportGrouping?: boolean;
  allowUsingProperty?: (property: FilterProperty) => boolean;
  supportedProperties: FilterProperty[][];
}

const Noop = () => null;

export function isGroup(condition: Condition) {
  return (
    condition?.operator === (CombineOperator.And as string) ||
    condition?.operator === (CombineOperator.Or as string)
  );
}
export interface LocationIndicator {
  index: number;
  location: 'above' | 'below';
}

function ConditionGroupNode({
  condition,
  parent,
  options,
  className,
  onChange,
  onChangeParent,
  setParentLocationIndicator,
}: {
  condition: Condition;
  parent: Condition;
  options: ConditionEditorOptions;
  className?: string;
  onChange: (condition: Condition) => void;
  onChangeParent: (parent: Condition) => void;
  setParentLocationIndicator: (location: LocationIndicator | undefined) => void;
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isGroupNode = isGroup(condition);

  return (
    <ConditionContainer
      sx={{ display: 'inline-flex' }}
      hideBorder={!options.supportGrouping && isGroupNode}
      enableHoverIndications={!isGroupNode}
      className={classNames(condition.operator, className, {
        hovered: isMenuOpen,
      })}
    >
      {isGroupNode ? (
        <ConditionGroupEditor
          condition={condition}
          options={options}
          onChange={onChange}
          onChangeParent={onChangeParent}
          parent={parent}
        />
      ) : (
        <AtomicConditionEditor
          condition={condition}
          onChange={onChange}
          options={options}
        />
      )}
      {parent &&
        onChangeParent &&
        (options.supportGrouping || !isGroupNode) && (
          <ConditionMenu
            condition={condition}
            parent={parent}
            options={options}
            onChangeParent={onChangeParent}
            onVisibilityChange={setIsMenuOpen}
            markParentLocation={setParentLocationIndicator}
          />
        )}
    </ConditionContainer>
  );
}

function ConditionGroupEditor({
  condition,
  parent,
  options,
  onChange,
  onChangeParent,
}: {
  condition: Condition;
  parent?: Condition;
  options: ConditionEditorOptions;
  onChange: (condition: Condition) => void;
  onChangeParent?: (parent: Condition) => void;
}) {
  const [locationIndicator, setLocationIndicator] = useState<
    LocationIndicator | undefined
  >();
  const [combineOperatorHover, setCombineOperatorHover] = useState(false);
  const onChangesFromChild = (
    prevCondition: Condition,
    newCondition: Condition
  ) => {
    // TODO(MoLow): add tests for cascading group conditions
    const shouldCascade =
      parent && onChangeParent && (newCondition.conditions?.length ?? 0) <= 1;
    if (shouldCascade) {
      onChangeParent({
        ...parent,
        conditions: parent.conditions?.flatMap((c) =>
          c === prevCondition ? (newCondition.conditions ?? []) : c
        ),
      });
    } else {
      onChange(newCondition);
    }
  };
  const children = condition.conditions?.map((c, i) => (
    <ConditionGroupNode
      options={options}
      condition={c}
      key={`${i}-${c.operator}-${c.property}`}
      parent={condition}
      className={classNames({
        hovered: combineOperatorHover,
        [`highlighted-${locationIndicator?.location}`]:
          locationIndicator?.index === i,
      })}
      onChange={(child) =>
        onChange({
          ...condition,
          conditions: condition.conditions?.map((cc, ii) =>
            ii === i ? child : cc
          ),
        })
      }
      onChangeParent={(newCondition) =>
        onChangesFromChild(condition, newCondition)
      }
      setParentLocationIndicator={setLocationIndicator}
    />
  ));
  if (!parent) {
    return <>{children}</>;
  }
  if (!options.supportGrouping) {
    return (
      <ConditionGroup hideBorder={true} sx={{ padding: 0 }}>
        {children}
      </ConditionGroup>
    );
  }
  return (
    <>
      <Select
        onMouseEnter={() => setCombineOperatorHover(true)}
        onMouseLeave={() => setCombineOperatorHover(false)}
        sx={{ ...inlineDropdownStyle, width: '80px', lineHeight: '24px' }}
        value={condition.operator}
        onChange={(event) =>
          onChange({ ...condition, operator: event.target.value })
        }
      >
        <MenuItem value={CombineOperator.And}>AND</MenuItem>
        <MenuItem value={CombineOperator.Or}>OR</MenuItem>
      </Select>
      <ConditionGroup hideBorder={true} sx={{ padding: 0 }}>
        {children}
      </ConditionGroup>
      <IconButton
        color='primary'
        onMouseEnter={() =>
          setLocationIndicator({
            index: (condition.conditions?.length ?? 0) - 1,
            location: 'below',
          })
        }
        onMouseLeave={() => setLocationIndicator(undefined)}
        onClick={() => {
          onChange({
            ...condition,
            conditions: [...(condition.conditions ?? []), { operator: '' }],
          });
          setLocationIndicator({
            index: condition.conditions?.length ?? 0,
            location: 'below',
          });
        }}
      >
        <i className='material-symbols-add-rounded text-xl' />
      </IconButton>
    </>
  );
}

function AtomicConditionEditor({
  condition,
  options,
  onChange,
}: {
  condition: Condition;
  options: ConditionEditorOptions;
  onChange: (condition: Condition) => void;
}) {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const conditionProperty = useMemo(
    () =>
      options.supportedProperties
        .flat()
        .find((c) => c.name === condition.property),
    [options.supportedProperties, condition.property]
  );

  const propertyOptions = useMemo(() => {
    const filter = (c: FilterProperty) =>
      c === conditionProperty ||
      (options.allowUsingProperty ?? (() => true))(c);
    return options.supportedProperties
      .map((group) => group.filter(filter))
      .filter((g) => g.length > 0);
  }, [
    options.supportedProperties,
    options.allowUsingProperty,
    conditionProperty,
  ]);

  const Dropdown = conditionProperty?.dropdown?.Component ?? Noop;
  const TextInput = conditionProperty?.textInput?.Component ?? Noop;
  return (
    <>
      {conditionProperty && (
        <>
          <PropertySelect
            condition={condition}
            onChange={onChange}
            properties={propertyOptions}
            MenuProps={{ disablePortal: true }}
          />
          <OperatorSelect
            condition={condition}
            onChange={onChange}
            propertyRelation={
              conditionProperty.policyRelation ??
              PropertyPolicyRelation.MultipleValues
            }
          />
          <Dropdown
            sx={{ ...inlineDropdownStyle, fontWeight: 'bold' }}
            MenuProps={{ disablePortal: true }}
            value={condition.value ?? []}
            multiple={
              condition.operator === ListOperator.In.toString() ||
              condition.operator === ListOperator.NotIn.toString() ||
              condition.operator === ListOperator.Contains.toString() ||
              condition.operator === ListOperator.ContainsNoneOf.toString() ||
              condition.operator === ListOperator.ContainsAnyOf.toString() ||
              condition.operator === ListOperator.NotContains.toString()
            }
            onChange={(event) =>
              onChange({
                ...condition,
                value: event.target.value as string[],
              })
            }
          />
          <TextInput
            sx={inlineDropdownStyle}
            value={condition.value?.[0] ?? ''}
            onChange={(event) =>
              onChange({ ...condition, value: [event.target.value] })
            }
          />
        </>
      )}
      {!conditionProperty && propertyOptions.length > 0 && (
        <>
          <Tooltip title='Add condition'>
            <Button
              variant='outlined'
              sx={{
                borderColor: 'transparent',
                width: 32,
                height: 32,
                padding: 0,
                minWidth: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => setIsAddOpen(true)}
            >
              <i className='material-symbols-add-rounded text-xl' />
            </Button>
          </Tooltip>
          <PropertySelect
            open={isAddOpen}
            properties={propertyOptions}
            onClose={() => setIsAddOpen(false)}
            sx={{ visibility: 'hidden', height: 0, width: 0, margin: 0 }}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
}

export function ConditionEditor({
  condition,
  options,
  onChange,
}: {
  condition: Condition | undefined;
  options: ConditionEditorOptions;
  onChange: (condition: Condition | undefined) => void;
}) {
  // this component takes care of wrapping the condition in a virtual group
  // and maintaining a single condition at the root level
  const virtualWrapper = {
    operator:
      (condition?.operator as CombineOperator) === CombineOperator.And
        ? CombineOperator.Or
        : CombineOperator.And,
    conditions: [condition].filter(Boolean),
  } as Condition;

  const onConditionChange = (
    condition: Condition,
    mergeIntoGroup?: boolean
  ) => {
    // TODO(MoLow): this function needs extensive testing of all branches:
    // 1. test removing last condition
    // 2. test adding first condition
    // 3. adding condition when there is an existing group should get merged into the group
    // 4. in all other cases, just use the wrapper condition as is, it wil be re-wrapped in the next re-render
    if (condition.conditions?.length === 0) {
      // removing last condition
      onChange(undefined);
    } else if (condition.conditions?.length === 1) {
      // adding first condition
      onChange(condition.conditions?.[0]);
    } else if (condition.conditions?.some(isGroup) && mergeIntoGroup) {
      // merge into group if possible
      const group = condition.conditions?.find(isGroup);
      const others = condition.conditions?.filter((c) => c !== group);
      onChange({
        operator: group?.operator ?? CombineOperator.And,
        conditions: [...(group?.conditions ?? []), ...others],
      });
    } else {
      // in all other cases, just use the wrapper as is, it wil be re-wrapped in the next re-render
      onChange({
        operator: condition.operator ?? CombineOperator.And,
        conditions: condition.conditions,
      });
    }
  };

  return (
    <ConditionContainer
      sx={{ display: 'inline-flex' }}
      className={`${virtualWrapper.operator} root`}
    >
      <ConditionGroupEditor
        condition={virtualWrapper}
        options={options}
        onChange={onConditionChange}
      />
      <AtomicConditionEditor
        condition={{ operator: '' }}
        options={options}
        onChange={(condition) =>
          onConditionChange(
            {
              ...virtualWrapper,
              conditions: [...virtualWrapper.conditions!, condition],
            },
            true
          )
        }
      />
    </ConditionContainer>
  );
}
