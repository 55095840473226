import { MenuItem, Select } from '@mui/material';
import type { Condition } from '@repo/api-gw-sdk';

import {
  PropertyPolicyRelation,
  ListOperator,
  StringOperator,
} from '@/types/advanceFilter';

import { inlineDropdownStyle } from './ConditionEditor';

export const OPERATORS = {
  [PropertyPolicyRelation.SingleValue]: [
    { value: ListOperator.In, label: 'is one of' },
    { value: ListOperator.NotIn, label: 'is not one of' },
  ],
  [PropertyPolicyRelation.MultipleValues]: [
    { value: ListOperator.ContainsAnyOf, label: 'contains any of' },
    { value: ListOperator.Contains, label: 'contains all of' },
    { value: ListOperator.ContainsNoneOf, label: 'contains none of' },
  ],
  [PropertyPolicyRelation.String]: [
    { value: StringOperator.In, label: 'is one of' },
    { value: StringOperator.NotIn, label: 'is not one of' },
    { value: StringOperator.Contains, label: 'contains' },
    { value: StringOperator.NotContains, label: 'does not contain' },
    { value: StringOperator.StartsWith, label: 'starts with' },
    { value: StringOperator.NotStartsWith, label: 'does not start with' },
    { value: StringOperator.EndsWith, label: 'ends with' },
    { value: StringOperator.NotEndsWith, label: 'does not end with' },
  ],
  [PropertyPolicyRelation.Tags]: [
    { value: StringOperator.Contains, label: 'contains' },
    { value: StringOperator.NotContains, label: 'does not contain' },
  ],
};

interface OperatorSelectProps {
  condition: Condition;
  propertyRelation: PropertyPolicyRelation;
  onChange: (condition: Condition) => void;
}

export function OperatorSelect({
  condition,
  propertyRelation,
  onChange,
}: OperatorSelectProps) {
  return (
    <Select
      sx={inlineDropdownStyle}
      MenuProps={{ disablePortal: true }}
      value={condition.operator ?? ''}
      onChange={(event) =>
        onChange({
          ...condition,
          operator: event.target.value,
          value: [],
        })
      }
    >
      {OPERATORS[propertyRelation].map((operator) => (
        <MenuItem key={operator.value} value={operator.value}>
          {operator.label}
        </MenuItem>
      ))}
    </Select>
  );
}
